import { registerListResourceWithStore } from '@/services/GenericListResourceService'
import store from '@/store'

import Dashboard from '@/modules/dashboard/Dashboard.vue'

import RouterPassThrough from '@/components/RouterPassThrough.vue'

import clocklogsManageRoutes from '@/views/manage/clocklogs/routes'
import disciplineManageRoutes from '@/views/manage/discipline/routes'
import messagingManageRoutes from '@/views/manage/messaging/routes'
import payrollManageRoutes from '@/views/manage/payroll/routes'
import punchesManageRoutes from '@/views/manage/punches/routes'
import scheduleManageRoutes from '@/views/manage/schedule/routes'
import timeCardsManageRoutes from '@/views/manage/timecards/routes'
import timeOffManageRoutes from '@/views/manage/timeoff/routes'

import billingRoutes from '@/views/settings/billing/routes'
import costingRoutes from '@/views/settings/costing/routes'
import deviceRoutes from '@/views/settings/devices/routes'
import disciplineSettingsRoutes from '@/views/settings/discipline/routes'
import integrationsRoutes from '@/views/settings/integrations/routes'
import orgRoutes from '@/views/settings/organization/routes'
import payrollRoutes from '@/views/settings/payroll/routes'
import punchSettingsRoutes from '@/views/settings/punches/routes'
import timeOffSettingsRoutes from '@/views/settings/timeoff/routes'

import shiftRoutes from '@/views/settings/shifts/routes'
import userRoutes from '@/views/settings/employees/routes'

registerListResourceWithStore('memorizedReports', 'reports/templates', store)

const items = [
  {
    name: 'dashboard',
    path: 'dashboard',
    label: 'Dashboard',
    icon: 'tachometer-alt',
    description: 'View dashboard overview of key metrics.',
    component: Dashboard,
    // We keep alive dashboard, because its state is expensive to compute.
    // TODO: We should probably instead store its state in vuex.
    keepAlive: true
  },
  {
    name: 'manage',
    path: 'manage',
    props: true,
    label: 'Manage',
    icon: 'user-tie',
    description: 'Manage live employee data.',
    component: RouterPassThrough,

    children: [
      disciplineManageRoutes,
      clocklogsManageRoutes,
      {
        name: 'geomap',
        path: 'geomap',
        label: 'Geo Mapping',
        icon: 'map-marked',
        description: 'View geo mapping results.',
        component: () => import(/* webpackChunkName: "geo-mapping" */ '@/views/manage/geomap/GeoMapping.vue')
          .catch(error => {
            console.error('Failed to route to GeoMapping', error)
            throw error
          }),
        requireFeature: 'geo',
        requireGetter: 'geoEnabled',
        requirePerm: 'manage_clock_logs',
      },
      messagingManageRoutes,
      payrollManageRoutes,
      punchesManageRoutes,
      scheduleManageRoutes,
      timeCardsManageRoutes,
      timeOffManageRoutes
    ]
  },
  {
    name: 'reports',
    label: 'Reports',
    path: 'reports',
    props: true,
    icon: 'chart-line',
    description: 'Run various reports.',
    component: RouterPassThrough,
    requirePerm: 'view_reports',
    children: [
      {
        name: 'absent',
        label: 'Absent',
        link: 'absent',
        path: 'absent/:view?',
        props: true,
        icon: 'plane',
        description: 'View absentees for selected date range.',
        component: () => import(/* webpackChunkName: "absentreport" */ '@/modules/reports/views/absent/AbsentReport.vue')
      },
      {
        name: 'absent-totals',
        label: 'Absent Totals',
        link: 'absent-totals',
        path: 'absent-totals/:view?',
        props: true,
        icon: 'jet-fighter',
        description: 'View absence analytics in a pivot table.',
        component: () => import(/* webpackChunkName: "absentreport" */ '@/modules/reports/views/absent/AbsentTotalsReport.vue')
      },
      {
        name: 'audit',
        label: 'Audit',
        link: 'audit',
        path: 'audit/:view?',
        icon: 'user-secret',
        description: 'View audit logs.',
        component: () => import(/* webpackChunkName: "auditreport" */ '@/modules/reports/views/audit/AuditReport.vue'),
        requirePerm: 'view_audit'
      },
      {
        name: 'audit-time-entry',
        label: 'Audit Time',
        link: 'audit-time-entry',
        path: 'audit-time-entry/:view?',
        icon: 'user-secret',
        description: 'View edited time entries.',
        component: () => import(/* webpackChunkName: "auditreport" */ '@/modules/reports/views/audit/AuditTimeEntryReport.vue'),
        requirePerm: 'view_audit'
      },
      {
        name: 'clock-logs-report',
        label: 'Clock Logs',
        link: 'clock-logs',
        path: 'clock-logs/:view?',
        icon: 'history',
        description: 'View clock logs for selected date range.',
        component: () => import(/* webpackChunkName: "clocklogsreport" */ '@/modules/reports/views/clocklog/ClockLogReport.vue')
      },
      {
        name: 'credential-report',
        label: 'Credentials',
        link: 'credentials',
        path: 'credentials/:view?',
        icon: 'id-badge',
        description: 'View user credentials, with features to track invalid and upcoming expiring credentials.',
        component: () => import(/* webpackChunkName: "credentialreport" */ '@/modules/reports/views/credential/CredentialReport.vue'),
        requireGetter: 'credentialsEnabled',
        require: (state, getters) => getters.canAccessEmployeeSensitiveField('credential')
      },
      {
        name: 'credential-roll-callreport',
        label: 'Credential Roll Call',
        link: 'credential-roll-call',
        path: 'credential-roll-call/:view?',
        icon: 'badge-check',
        description: 'View valid typed user credentials for workers who are currently clocked IN.',
        component: () => import(/* webpackChunkName: "credentialrollcallreport" */ '@/modules/reports/views/credential/CredentialRollCallReport.vue'),
        requireGetter: 'credentialsEnabled',
        require: (state, getters) => getters.canAccessEmployeeSensitiveField('credential')
      },
      {
        name: 'job-transfer-report',
        label: 'Job Transfers',
        link: 'job-transfers',
        path: 'job-transfers/:view?',
        icon: 'sign-in-alt',
        description: 'View worker time cards, in job-transfer style.',
        component: () => import(/* webpackChunkName: "jobtransferreport" */ '@/modules/reports/views/jobtransfer/JobTransferReport.vue'),
        requireGetter: 'costingEnabled'
      },
      {
        name: 'net-pay-analysis-report',
        label: 'Net Pay Analysis',
        link: 'net-pay-analysis',
        path: 'net-pay-analysis/:view?',
        props: true,
        icon: 'analytics',
        description: 'Analyze net pay, by component and/or per worker.',
        component: () => import(/* webpackChunkName: "netpayanalysisreport" */ '@/modules/reports/views/payrun/NetPayAnalysisReport.vue'),
        requireFeature: 'netpay',
        requirePerm: 'manage_payroll',
        requireGetter: 'netPayEnabled',
      },
      {
        name: 'payroll-report',
        label: 'Payroll',
        link: 'payroll',
        path: 'payroll/:view?',
        icon: 'money-bill',
        description: 'View worker payroll, with custom export and template support.',
        component: () => import(/* webpackChunkName: "payrollreport" */ '@/modules/reports/views/payroll/PayrollReport.vue'),
        requireFeature: 'integration'
      },
      {
        name: 'pay-run-analysis-report',
        label: 'Pay Run Analysis',
        link: 'pay-run-analysis',
        path: 'pay-run-analysis/:view?',
        props: true,
        icon: 'analytics',
        description: 'Analyze pay runs, by total and/or per worker.',
        component: () => import(/* webpackChunkName: "payrunanalysisreport" */ '@/modules/reports/views/payrun/PayRunAnalysisReport.vue'),
        requireFeature: 'pay_run',
        requirePerm: 'manage_payroll',
        requireGetter: 'payRunEnabled',
      },
      {
        name: 'pay-run-code-analysis-report',
        label: 'Pay Run Code Analysis',
        link: 'pay-run-code-analysis',
        path: 'pay-run-code-analysis/:view?',
        props: true,
        icon: 'analytics',
        description: 'Analyze pay runs, by pay and/or time off code.',
        component: () => import(/* webpackChunkName: "payrunanalysisreport" */ '@/modules/reports/views/payrun/PayRunCodeAnalysisReport.vue'),
        requireFeature: 'pay_run',
        requirePerm: 'manage_payroll',
        requireGetter: 'payRunEnabled',
      },
      {
        name: 'pay-run-labor-analysis-report',
        label: 'Pay Run Labor Analysis',
        link: 'pay-run-labor-analysis',
        path: 'pay-run-labor-analysis/:view?',
        props: true,
        icon: 'analytics',
        description: 'Analyze pay runs, by entry date and/or job.',
        component: () => import(/* webpackChunkName: "payrunanalysisreport" */ '@/modules/reports/views/payrun/PayRunLaborAnalysisReport.vue'),
        requireFeature: 'pay_run',
        requirePerm: 'manage_payroll',
        requireGetter: 'payRunEnabled',
      },
      {
        name: 'raw-punches-report',
        label: 'Raw Punches',
        link: 'punches',
        path: 'punches/:view?',
        icon: 'columns',
        description: 'View worker punches, with advanced customization options.',
        component: () => import(/* webpackChunkName: "rawpunchreport" */ '@/modules/reports/views/rawpunch/RawPunchReport.vue')
          .catch(error => {
            console.error('Failed to route to RawPunchReport', error)
            throw error
          })
      },
      {
        name: 'raw-punch-totals-report',
        label: 'Raw Punch Totals',
        link: 'raw-punch-totals',
        path: 'raw-punch-totals/:view?',
        icon: 'table',
        description: 'View raw punch totals.',
        component: () => import(/* webpackChunkName: "rawpunchreport" */ '@/modules/reports/views/rawpunchtotals/RawPunchTotalsReport.vue')
          .catch(error => {
            console.error('Failed to route to RawPunchTotalsReport', error)
            throw error
          })
      },
      {
        name: 'roll-call',
        label: 'Roll Call',
        link: 'rollcall',
        path: 'rollcall/:view?',
        props: true,
        icon: 'bullhorn',
        description: 'View workers currently clocked in or not.',
        component: () => import(/* webpackChunkName: "rollcallreport" */ '@/modules/reports/views/rollcall/RollCallReport.vue')
      },
      {
        name: 'salary-report',
        label: 'Salary',
        link: 'salary',
        path: 'salary/:view?',
        icon: 'money-check',
        description: 'View worker salary summary.',
        component: () => import(/* webpackChunkName: "salaryreport" */ '@/modules/reports/views/salary/SalaryReport.vue'),
        // TODO: After data remodelling for time entries, we won't require scheduleEnabled.
        requireGetter: ['canViewWorkerPay', 'payrollEnabled', 'scheduleEnabled']
      },
      {
        name: 'schedule-report',
        label: 'Schedule',
        link: 'schedule',
        path: 'schedule/:view?',
        icon: 'calendar-alt',
        description: 'View worker shift schedule, by day.',
        component: () => import(/* webpackChunkName: "scheduledreport" */ '@/modules/reports/views/schedule/ScheduleReport.vue'),
        requirePerm: 'manage_schedule',
        requireGetter: 'scheduleEnabled'
      },
      {
        name: 'shiftcards-report',
        label: 'Shift Summaries',
        link: 'shiftcards',
        path: 'shiftcards/:view?',
        icon: 'table',
        description: 'View worker shift summaries.',
        component: () => import(/* webpackChunkName: "shiftcardreport" */ '@/modules/reports/views/shiftcard/ShiftCardReport.vue'),
        requireFeature: 'schedule',
        requireGetter: 'scheduleEnabled'
      },
      {
        name: 'tardy',
        label: 'Tardy',
        link: 'tardy',
        path: 'tardy/:view?',
        props: true,
        icon: 'bed',
        description: 'View workers who clocked IN late to work for selected date range, and total tardy hours.',
        component: () => import(/* webpackChunkName: "tardyreport" */ '@/modules/reports/views/tardy/TardyReport.vue')
      },
      {
        name: 'timecards-report',
        label: 'Time Cards',
        link: 'timecards',
        path: 'timecards/:view?',
        icon: 'table',
        description: 'View worker work hours, by day.',
        component: () => import(/* webpackChunkName: "timecardreport" */ '@/modules/reports/views/timecard/TimeCardReport.vue')
      },
      {
        name: 'timeoff-report',
        label: 'Time Off',
        link: 'timeoff',
        path: 'timeoff/:view?',
        icon: 'umbrella-beach',
        description: 'View time off entries.',
        component: () => import(/* webpackChunkName: "timeoffreport" */ '@/modules/reports/views/timeoff/TimeOffReport.vue'),
        requireFeature: 'pto',
        requireGetter: 'timeOffEnabled'
      },
      {
        name: 'time-off-balance-report',
        label: 'Time Off Balances',
        link: 'time-off-balance',
        path: 'time-off-balance/:view?',
        icon: 'balance-scale',
        description: 'View time off balances.',
        component: () => import(/* webpackChunkName: "timeoffbalancereport" */ '@/modules/reports/views/timeoff/TimeOffBalanceReport.vue')
          .catch(error => {
            console.error('Failed to route to DepartmentList', error)
            throw error
          }),
        requireFeature: 'pto',
        requireGetter: 'timeOffAccrualEnabled'
      },
      {
        name: 'time-off-totals-report',
        label: 'Time Off Totals',
        link: 'time-off-totals',
        path: 'time-off-totals/:view?',
        icon: 'island-tropical',
        description: 'View time off totals.',
        component: () => import(/* webpackChunkName: "timeofftotalsreport" */ '@/modules/reports/views/timeofftotals/TimeOffTotalsReport.vue'),
        requireFeature: 'pto',
        requireGetter: 'timeOffEnabled'
      },
      {
        name: 'total-report',
        label: 'Totals',
        link: 'totals',
        path: 'totals/:view?',
        icon: 'money-check-dollar',
        description: 'View hour and wage totals, with various grouping options.',
        component: () => import(/* webpackChunkName: "totalreport" */ '@/modules/reports/views/total/TotalReport.vue')
      },
      {
        name: 'memorized',
        label: 'Memorized Reports',
        path: 'memorized',
        icon: 'lightbulb',
        description: 'View and run memorized reports.',
        component: () => import(/* webpackChunkName: "memorizedreports" */ '@/modules/reports/views/memorizedreports/TheMemorizedReports.vue')
      }
    ]
  },
  {
    name: 'settings',
    label: 'Settings',
    path: 'settings',
    props: true,
    icon: 'cog',
    description: 'View and edit your account settings.',
    component: Object.assign({}, RouterPassThrough, { name: 'Settings' }),
    requireActive: false,
    children: [
      disciplineSettingsRoutes,
      billingRoutes,
      costingRoutes,
      deviceRoutes,
      integrationsRoutes,
      orgRoutes,
      payrollRoutes,
      punchSettingsRoutes,
      shiftRoutes,
      timeOffSettingsRoutes,
      userRoutes
    ]
  },
  {
    name: 'byxbi',
    label: 'Byxbi',
    path: 'byxbi',
    icon: 'people-roof',
    description: 'Byxbi-specific management.',
    component: () => import(/* webpackChunkName: "byxbi" */ '@/views/byxbi/Byxbi.vue'),
    requireGetter: 'canManageByxbi'
  }
]

function createNavItem (item) {
  return {
    name: item.label,
    url: '/' + (item.link || item.path),
    icon: item.icon,
    badge: item.badge,
    nav: item.nav !== false,
    requireActive: item.requireActive,
    requireFeature: item.requireFeature,
    requirePerm: item.requirePerm,
    requireGetter: item.requireGetter,
    require: item.require
  }
}

const navItems = items.map(item => {
  const navItem = createNavItem(item)
  if (item.children) {
    navItem.children = item.children.map(child => {
      const childNavItem = createNavItem(child)
      childNavItem.url = `${navItem.url}${childNavItem.url}`
      return childNavItem
    })
  }

  return navItem
})

function createRouteItem (item) {
  return {
    name: item.name,
    path: item.path,
    redirect: item.redirect,
    beforeEnter: item.beforeEnter,
    component: item.component,
    props: item.props,
    children: item.children && item.children.map(child => createRouteItem(child)),
    meta: {
      nav: item.nav !== false, // distinguish navigation route vs not (e.g., error, sign-up)
      icon: item.icon,
      label: item.label,
      title: item.title,
      link: item.link || item.path,
      badge: item.badge,
      description: item.description,
      requireActive: item.requireActive,
      requireFeature: item.requireFeature,
      requirePerm: item.requirePerm,
      requireGetter: item.requireGetter,
      require: item.require,
      keepAlive: item.keepAlive,
      breadcrumbLeaf: item.breadcrumbLeaf
    }
  }
}

const routeItems = items.map(item => createRouteItem(item))

export default {
  navItems,
  routeItems
}
