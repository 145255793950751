<template>
  <b-modal
    v-bind="$attrs"
    no-trap
    title="Print Preferences"
    :ok-title="okTitle"
    :ok-disabled="v$.$invalid"
    hide-header-close
    no-close-on-backdrop
    no-fade
    centered
    @ok="ok"
  >
    <form-group v-if="showPagePrefs" label="Layout" class="layout">
      <b-form-radio-group v-model="layout">
        <b-form-radio value="portrait">
          Portrait
          <font-awesome-icon icon="file-image" />
        </b-form-radio>
        <b-form-radio value="landscape">
          Landscape
          <font-awesome-icon icon="image" />
        </b-form-radio>
      </b-form-radio-group>
    </form-group>
    <form-group v-if="showScale" label="Scale" class="scale"
      :state="v$.scale.$invalid ? false : null"
      invalid-feedback="Scale amount must be a number between 10 and 200."
    >
      <template #label>
        Scale
        <help-text-icon>
          Setting a custom <code>Scale</code> is particularly useful when the grid table is too wide to fit on the page.
          You can set a scale less than 100% in order to make it fit.
          <template v-if="outerGroupName">
            <br><br>
            Note that setting a custom scale doesn't work well with the option below to
            <code>Print separate page per {{ outerGroupName }}</code>. You'll instead want to set the scale using your
            browser's actual print preferences.
          </template>
        </help-text-icon>
      </template>
      <b-form-radio-group v-model="scaleRadioValue">
        <b-form-radio value="default">
          Default
        </b-form-radio>
        <b-form-radio value="custom">
          <div class="custom-scale">
            Custom
            <template v-if="scaleRadioValue === 'custom'">
              <b-form-input v-model.number="scale" type="number" min="10" max="200"></b-form-input>
              %
            </template>
          </div>
        </b-form-radio>
      </b-form-radio-group>
    </form-group>
    <form-group v-if="showIncludeTitles">
      <b-form-checkbox v-model="includeTitles">Include titles</b-form-checkbox>
    </form-group>
    <form-group v-if="showIncludeTitles && includeTitles">
      <b-form-checkbox v-model="repeatTitles" :disabled="repeatTitlesRequired">Repeat titles on every page</b-form-checkbox>
    </form-group>
    <form-group v-if="outerGroupName">
      <b-form-checkbox v-model="pageBreakPerGroup">Print separate page per {{ outerGroupName }}</b-form-checkbox>
    </form-group>
  </b-modal>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { between, integer } from '@vuelidate/validators'
import HelpTextIcon from '@/components/HelpTextIcon.vue'

export default {
  name: 'PrintModal',
  inheritAttrs: false,
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    HelpTextIcon
  },
  props: {
    okTitle: {
      type: String,
      default: 'Print'
    },
    // The following value is only used for initial value when modal is mounted.
    value: Object,
    outerGroupName: String,
    showPagePrefs: {
      type: Boolean,
      default: false
    },
    showScale: {
      type: Boolean,
      default: true
    },
    showIncludeTitles: {
      type: Boolean,
      default: true
    }
  },
  emits: ['ok'],
  data () {
    return {
      layout: 'portrait',
      scale: null, // a number, or null for default
      includeTitles: true,
      repeatTitles: false,
      pageBreakPerGroup: false,

    }
  },
  computed: {
    scaleRadioValue: {
      get () {
        return Number.isInteger(this.scale) ? 'custom' : 'default'
      },
      set (v) {
        if (v === 'default') this.scale = null
        else if (!Number.isInteger(this.scale)) this.scale = 100
      }
    },
    formInvalid () {
      return this.v$.$invalid
    },
    repeatTitlesRequired () {
      // When printing ag-grid with pageBreakPerGroup, <table> element must be visible root;
      // so we can't print non-repeating titles.
      return this.pageBreakPerGroup && this.includeTitles
    }
  },
  watch: {
    includeTitles (includeTitles) {
      if (!includeTitles) this.repeatTitles = false
    },
    repeatTitlesRequired (repeatTitlesRequired) {
      if (repeatTitlesRequired) {
        this.repeatTitles = true
      }
    }
  },
  mounted () {
    if (this.value) Object.assign(this, this.value)
  },
  methods: {
    ok () {
      this.$emit('ok', {
        includeTitles: this.showIncludeTitles ? this.includeTitles : undefined,
        repeatTitles: this.showIncludeTitles ? this.repeatTitles : undefined,
        pageBreakPerGroup: this.pageBreakPerGroup,
        layout: this.showPagePrefs ? this.layout : undefined,
        scale: this.showScale ? this.scale : undefined
      })
    }
  },
  validations () {
    return {
      scale: {
        integer,
        // Chrome requires scale between 10-200%.
        between: between(10, 200)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.form-group {
  margin-bottom: 0.25rem;

  &.layout {
    margin-bottom: .5rem;
    svg {
      margin-left: .5rem;
    }
  }
  &.scale {
    margin-bottom: 1rem;
    .custom-scale {
      display: flex;
      .form-control {
        position: relative;
        bottom: 7px;
        margin-left: 1rem;
        margin-right: 3px;
      }
    }
  }
}
</style>
